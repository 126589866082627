import { GridFilterModel, GridLinkOperator } from '@mui/x-data-grid-pro'
import useForm from '@utils/hooks/useForm'
import moment from 'moment'
import uniqid from 'uniqid'

type TtransformFormStateToFilterModel = {
  formState: ReturnType<typeof useForm>
  options: {
    omitTimeRange?: boolean
    resetTimeRange?: boolean
    isoSupport?: boolean
  }
}

export const transformFormStateToFilterModel = (
  formState: TtransformFormStateToFilterModel['formState'],
  options: TtransformFormStateToFilterModel['options'] = {}
) => {
  const { omitTimeRange = false, resetTimeRange = false } = options
  const {
    property,
    property_value,
    results_since,
    results_until,
    host_ids = []
  } = formState.values

  const filterProps: GridFilterModel = {
    items: [
      {
        columnField: `data.results.${property}`,
        operatorValue: 'equals',
        value: property_value,
        id: uniqid.time()
      }
    ],
    linkOperator: 'and' as GridLinkOperator
  }

  if (!omitTimeRange) {
    filterProps.items.push(
      {
        columnField: 'data.end_time',
        operatorValue: 'onOrAfter',
        value: resetTimeRange
          ? moment().subtract(3, 'days').format('YYYY-MM-DDTHH:MM')
          : moment(results_since).format('YYYY-MM-DDTHH:MM'),
        id: uniqid.time()
      },
      {
        columnField: 'data.end_time',
        operatorValue: 'onOrBefore',
        value: resetTimeRange
          ? moment().format('YYYY-MM-DDTHH:MM')
          : moment(results_until).format('YYYY-MM-DDTHH:MM'),
        id: uniqid.time()
      }
    )
  }

  if (host_ids && host_ids.length > 0) {
    const hostFilters = host_ids.map((hostname: string) => ({
      columnField: 'header.hostname',
      operatorValue: 'equals',
      value: hostname,
      id: uniqid.time()
    }))

    filterProps.items = [...filterProps.items, ...hostFilters]
  }

  return filterProps
}

export const encodeParam = (param: string) => {
  if (!param) return param
  let string = window.btoa(param)
  return string
}

export const decodeParam = (param: string) => {
  if (!param) return param

  let string = window.atob(param)

  return string
}

export const supportedValue = (value: any): boolean => {
  return (
    // Blank values
    value !== '' &&
    value !== null &&
    value !== undefined &&
    !Array.isArray(value)
  )
}
