import { Card, Button } from '@components'
import { TUseApiHook } from '@hooks/useApi'
import { Box, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { HostResponse, TResultResponse } from '@utils/api'
import { classifyTags } from '@utils/tags'
import moment from 'moment'
import React, { useMemo } from 'react'
import { resultLabel } from './resultLabel'

const ResultDetailPageSandfly = ({
  api,
  hostApi
}: {
  api: TUseApiHook<TResultResponse>
  hostApi: TUseApiHook<HostResponse>
}) => {
  const { response, loading } = api
  const tagStrings = response?.data.tags || []
  const { mitreTechnique, mitreTactic } = classifyTags(tagStrings)
  const host = hostApi.response ? hostApi.response : false

  const isWhitelisted =
    response && host
      ? host.sandfly_whitelist.indexOf(response?.data.name) > -1
      : false

  const color = useMemo(() => {
    const isFailure = response?.data.status === 'alert'
    const isError = response?.data.status === 'error'

    if (isFailure) return 'error'
    if (isError) return 'warning'

    return undefined
  }, [loading])

  return (
    <Card
      heading="Sandfly Details"
      headingButton={
        <Button
          size="small"
          variant="outlined"
          to={`/sandflies/sandfly/${response?.data.name}/`}
        >
          View Sandfly
        </Button>
      }
      sx={{ height: '100%' }}
      color={color}
    >
      <Box p={2}>
        <Typography variant="h6">
          <Box
            component="span"
            sx={
              isWhitelisted
                ? { textDecoration: 'line-through', opacity: 0.65 }
                : null
            }
          >
            <span className="result-status">
              {response && `${resultLabel(response.data.status)}: `}
            </span>
            {response?.data.name}
          </Box>
          {isWhitelisted && <span>{` (whitelisted)`}</span>}
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1}>
          <pre>{response?.data.end_time}</pre>
          <span>{`(${moment(response?.data.end_time).fromNow()})`}</span>
        </Stack>

        <Box pb={2}>
          <Grid container spacing={1}>
            {[...mitreTactic, ...mitreTechnique].map(
              ({ label, tooltip, reference }) => (
                <Grid item key={label}>
                  <Tooltip title={tooltip}>
                    <Chip
                      component="a"
                      clickable={!!reference}
                      onClick={() => {
                        reference &&
                          window.open(`${reference}`, '_blank', 'noopener')
                      }}
                      variant="outlined"
                      label={label}
                    />
                  </Tooltip>
                </Grid>
              )
            )}
          </Grid>
        </Box>

        <Box>
          <Typography variant="body2">
            {response?.data.results.explanation}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default ResultDetailPageSandfly
