import { Button, Card } from '@components'
import { TUseApiHook } from '@hooks/useApi'
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { HostResponse, TResultResponse } from '@utils/api'
import { encodeParam, supportedValue } from '@utils/hunter'
import { getPropertyFromDotNotation } from '@utils/misc'
import {
  getChildPropsFromArrayProp,
  getPropRecommendationsByEngine
} from '@utils/sandfly'
import { navigate } from 'gatsby'
import React, { FC } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

type Props = {
  api: TUseApiHook<TResultResponse>
  hostApi: TUseApiHook<HostResponse>
}

const ResultDetailPageHunter: FC<Props> = ({ api }) => {
  const { response } = api
  const [open, setOpen] = React.useState<number | null>(null)
  const { palette } = useTheme()
  const recommendations = response
    ? getPropRecommendationsByEngine(response?.data.engine)
    : []

  const searchProps = recommendations?.map((property) => {
    const value =
      response?.data &&
      getPropertyFromDotNotation(property, response.data.results)

    return {
      property,
      value,
      id: response?.id
    }
  })

  const arrayFields =
    searchProps?.filter(
      ({ value }) => Array.isArray(value) && value.length > 0
    ) || []

  const searchArrays = arrayFields.map(({ property, value = [], id }) => {
    const arrayProperties = Array.isArray(value)
      ? value.map((item: any) => {
          const isString = typeof item === 'string'

          if (isString) {
            return [
              {
                property,
                value: item
              }
            ]
          }

          const properties = getChildPropsFromArrayProp(property)

          return properties
            ? properties.map((property) => ({
                property,
                value: getPropertyFromDotNotation(property, item)
              }))
            : []
        })
      : []

    return {
      id,
      property,
      arrayProperties
    }
  }) as {
    id: string
    property: string
    arrayProperties: { value: string; property: string }[][]
  }[]

  /* Stalker Data */
  return (
    <Box maxWidth="100%">
      <Card
        heading="Sandfly Hunter Data Points"
        headingButton={!searchProps && 'None Available :('}
        feature="sandfly_hunter"
      >
        <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
          <TableBody>
            {(!searchProps || searchProps.length < 1) && (
              <TableRow>
                <TableCell width="80%">
                  <Typography variant="subtitle1">{'Sorry'}</Typography>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                    variant="subtitle2"
                  >
                    {'no recommendations for this sandfly yet.'}
                  </Typography>
                </TableCell>
                <TableCell align="right" width="20%"></TableCell>
              </TableRow>
            )}
            {searchProps &&
              searchProps
                .filter(({ value }) => supportedValue(value))
                .map(({ property, value, id }, topIndex) => {
                  return (
                    <TableRow key={`${property}-${topIndex}`}>
                      <TableCell width="80%">
                        <Typography variant="subtitle1">{property}</Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {value}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" width="20%">
                        <Button
                          variant="outlined"
                          feature="sandfly_hunter"
                          onClick={() =>
                            navigate(
                              `/results/hunter/${id}/${property}/${encodeParam(
                                `${value}`
                              )}/`,
                              {
                                state: {
                                  property,
                                  property_value: value,
                                  options: searchProps
                                }
                              }
                            )
                          }
                        >
                          Search
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
            {searchArrays &&
              searchArrays.map(({ property, arrayProperties, id }, topIndex) =>
                arrayProperties.map((item, index) => {
                  const hasSubfields = item.length > 1
                  return (
                    <TableRow key={`${property}-${topIndex}-${index}`}>
                      <TableCell colSpan={2}>
                        <Grid
                          container
                          spacing={2}
                          wrap="nowrap"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs alignContent="space-between">
                            <Typography variant="subtitle1">
                              {`${property}[${index}]`}{' '}
                              <Chip
                                color="primary"
                                size="small"
                                label="Array Data"
                              />
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{
                                '& dl ': {
                                  m: 0,
                                  fontSize: '0.875rem'
                                },
                                '& dt': {
                                  fontWeight: 700,
                                  display: 'inline-block',

                                  '&:not(:first-child):before': {
                                    content: `', '`
                                  }
                                },

                                '& dd': {
                                  display: 'inline-block',
                                  my: 0,
                                  mx: 1
                                }
                              }}
                            >
                              <dl>
                                {item?.[0]?.value && (
                                  <>
                                    <dt>{item?.[0].property}:</dt>
                                    <dd> {item?.[0].value}</dd>
                                  </>
                                )}
                                {item?.[1]?.value && (
                                  <>
                                    <dt>{item?.[1].property}:</dt>
                                    <dd> {item?.[1].value}</dd>
                                  </>
                                )}
                              </dl>
                            </Typography>
                          </Grid>
                          <Grid item xs style={{ flexGrow: 0 }}>
                            {hasSubfields ? (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  setOpen(open !== index ? index : null)
                                }
                              >
                                {open ? (
                                  <KeyboardArrowUp />
                                ) : (
                                  <KeyboardArrowDown />
                                )}
                              </IconButton>
                            ) : (
                              <Button
                                variant="outlined"
                                feature="sandfly_hunter"
                                onClick={() =>
                                  navigate(
                                    `/results/hunter/${id}/${
                                      item?.[0]?.property
                                    }[]/${encodeParam(`${item?.[0]?.value}`)}/`,
                                    {
                                      state: {
                                        property: `${item?.[0]?.property}[]`,
                                        property_value: item?.[0]?.value
                                      }
                                    }
                                  )
                                }
                              >
                                Search
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        <Collapse
                          in={open === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            sx={{
                              mt: 1,
                              p: 2,
                              borderRadius: 1,
                              border: `1px solid ${palette.divider}`,

                              '& > div:not(:last-child)': {
                                mb: 1
                              }
                            }}
                          >
                            {/* <Stack spacing={0.25}> */}
                            {item.map((row, innerIndex) => (
                              <Grid
                                container
                                spacing={2}
                                wrap="nowrap"
                                key={`${property}-${index}-${innerIndex}-${row.property}`}
                              >
                                <Grid item xs={2}>
                                  <Typography variant="subtitle2">
                                    {row.property}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body2" noWrap>
                                    {row.value}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{ textAlign: 'right' }}
                                >
                                  <Button
                                    feature="sandfly_hunter"
                                    disabled={!supportedValue(row.value)}
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      navigate(
                                        `/results/hunter/${id}/${property}[].${
                                          row.property
                                        }/${encodeParam(`${row.value}`)}/`,
                                        {
                                          state: {
                                            property: `${property}[].${row.property}`,
                                            property_value: row.value
                                          }
                                        }
                                      )
                                    }
                                  >
                                    Search
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
          </TableBody>
        </Table>
      </Card>
    </Box>
  )
}

export default ResultDetailPageHunter
