import React from 'react'
import { Button, Card } from '@components'
import { TUseApiHook } from '@hooks/useApi'
import { Box, Divider, Typography } from '@mui/material'
import { Skeleton, Alert } from '@mui/material'
import { HostResponse, TResultResponse } from '@utils/api'

const ResultDetailPageHost = ({
  api,
  hostApi
}: {
  api: TUseApiHook<TResultResponse>
  hostApi: TUseApiHook<HostResponse>
}) => {
  /* Host info */
  const { response } = api
  const host = hostApi.response ? hostApi.response : false
  const { loading } = hostApi

  return (
    <Card
      heading="Host Summary"
      headingButton={
        <Button
          size="small"
          disabled={loading || !host}
          variant="outlined"
          to={`/hosts/host/${response?.header.host_id}/`}
        >
          View Host
        </Button>
      }
    >
      {loading && (
        <>
          <Box p={2}>
            <Skeleton />
            <Skeleton />
          </Box>
          <Divider />
          <Box p={2}>
            <Skeleton />
            <Skeleton />
          </Box>
          <Divider />
          <Box p={2}>
            <Skeleton />
            <Skeleton />
          </Box>
        </>
      )}

      {!loading && host && (
        <>
          <Box p={2}>
            <Typography variant="overline">Hostname</Typography>
            <Typography variant="body2">{host.os.info.node}</Typography>
          </Box>
          <Divider />

          <Box p={2}>
            <Typography variant="overline">Target Address</Typography>
            <Typography variant="body2">{host.hostname}</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Typography variant="overline">IP (last seen)</Typography>
            <Typography variant="body2">{host.last_seen_ip_addr}</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Typography variant="overline">Platform</Typography>
            <Typography variant="body2">{host.os.info.platform}</Typography>
          </Box>
        </>
      )}

      {!loading && !host && (
        <>
          <Box p={2}>
            <Typography variant="overline">Target Address</Typography>
            <Typography variant="body2">{response?.header.hostname}</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Typography variant="overline">IP</Typography>
            <Typography variant="body2">{response?.header.ip_addr}</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Alert severity="info">Host no longer registered</Alert>
          </Box>
        </>
      )}
    </Card>
  )
}

export default ResultDetailPageHost
