import { TResultDetail } from '@utils/api'

export const resultLabel = (status: TResultDetail['data']['status']) => {
  switch (status) {
    case 'alert':
      return 'Failed'
    case 'error':
      return 'Error'
    case 'pass':
    default:
      return 'Passed'
  }
}
