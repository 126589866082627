import { GridLinkOperator } from '@mui/x-data-grid-pro'
import {
  SandflyEngine,
  TResultDetail,
  TResultDataKey,
  Sandfly,
  SandflyType
} from './api'

export const sandflyCommonTypes: SandflyType[] = [
  'user',
  'process',
  'policy',
  'file',
  'directory',
  'log',
  'recon'
]

export const sandflySpecialTypes: SandflyType[] = [
  'incident',
  'template',
  'error'
]

export const sandflyTypes: SandflyType[] = [
  ...sandflyCommonTypes,
  ...sandflySpecialTypes
]

export const getResultsPropByEngine = (
  engine: SandflyEngine
): TResultDataKey => {
  switch (engine) {
    case 'file':
      return 'file'
    case 'systemd':
      return 'systemd'
    case 'systemd_session':
      return 'systemd_user'
    case 'dir':
      return 'directory'
    case 'user':
    case 'user_password_hash_duplicates':
      return 'user'
    case 'lastlog':
    case 'wtmp':
    case 'utmp':
    case 'btmp':
    case 'log_tampering_lastlog_wtmp_missing_record':
    case 'log_tampering_lastlog_history_missing_record':
      return 'log'
    case 'at_jobs':
      return 'atjob'
    case 'cron':
      return 'cron'
    case 'os_identify':
      return 'os'
    case 'error':
      return 'error'
    case 'kmodules':
      return 'kernel_module'
    case 'process':
    case 'process_masquerade_binary_renamed':
    case 'process_masquerade_binary_mismatched':
    case 'process_masquerade_mixed_case':
    case 'process_binary_time_identical':
    default:
      return 'process'
  }
}

export const getPropRecommendationsByEngine = (
  engine: SandflyEngine | TResultDetail['data']['engine']
) => {
  if (engine.indexOf('process') > -1) {
    return [
      'process.name',
      'process.cmdline',
      'process.command',
      'process.path',
      'process.uid_name',
      'process.hash.md5',
      'process.hash.sha1',
      'process.hash.sha256',
      'process.hash.sha512'
    ]
  }

  if (engine.indexOf('file') > -1) {
    return [
      'file.name',
      'file.path',
      'file.uid_name',
      'file.hash.md5',
      'file.hash.sha1',
      'file.hash.sha256',
      'file.hash.sha512'
    ]
  }

  if (engine.indexOf('kmodules') > -1) {
    return ['kernel_module.name']
  }

  if (engine.indexOf('dir') > -1) {
    return ['directory.name', 'directory.path', 'directory.uid_name']
  }

  if (engine.indexOf('user') > -1) {
    return [
      'user.username',
      'user.gid_name',
      'user.group_membership',
      'user.uid',
      'user.gecos',
      'user.home_dir',
      'user.password.hash.md5',
      'user.password.hash.sha1',
      'user.password.hash.sha256',
      'user.password.hash.sha512',
      'user.password.type',
      'user.ssh.key',
      'user.ssh.hash',
      'user.ssh.comment',
      'user.ssh.type',
      'user.ssh.authorized_keys.data'
    ]
  }

  if (engine.indexOf('lastlog') > -1) {
    return [
      'log.lastlog.username',
      'log.lastlog.uid',
      'log.lastlog.terminal',
      'log.lastlog.hostname'
    ]
  }

  if (engine.indexOf('wtmp') > -1) {
    return [
      'log.wtmp.type_name',
      'log.wtmp.device',
      'log.wtmp.username',
      'log.wtmp.hostname',
      'log.wtmp.ip_address'
    ]
  }

  if (engine.indexOf('utmp') > -1) {
    return [
      'log.utmp.type_name',
      'log.utmp.device',
      'log.utmp.username',
      'log.utmp.hostname',
      'log.utmp.ip_address'
    ]
  }

  if (engine.indexOf('btmp') > -1) {
    return [
      'log.btmp.type_name',
      'log.btmp.device',
      'log.btmp.username',
      'log.btmp.hostname',
      'log.btmp.ip_address'
    ]
  }

  if (engine.indexOf('log_tampering_lastlog') > -1) {
    return [
      'log.lastlog.username',
      'log.lastlog.uid',
      'log.lastlog.terminal',
      'log.lastlog.hostname'
    ]
  }

  if (engine.indexOf('log_tampering_wtmp') > -1) {
    return [
      'log.wtmp.type_name',
      'log.wtmp.device',
      'log.wtmp.username',
      'log.wtmp.hostname',
      'log.wtmp.ip_address'
    ]
  }

  if (engine.indexOf('at_jobs') > -1) {
    return ['atjob.username', 'atjob.command']
  }

  if (engine.indexOf('cron') > -1) {
    return ['cron.username', 'cron.command', 'cron.entry']
  }

  if (engine.indexOf('os_identify') > -1) {
    return [
      'os.info.release',
      'os.info.version',
      'os.info.uname',
      'os.info.node',
      'os.os_release.pretty_name'
    ]
  }

  if (engine.indexOf('systemd_session') > -1) {
    return ['systemd_user.username', 'systemd_user.uid', 'systemd_user.gid']
  }

  if (engine.indexOf('systemd') > -1) {
    return [
      'systemd.name',
      'systemd.exec_reload.binary_path',
      'systemd.exec_reload.file.hash.sha512',
      'systemd.exec_reload.name',
      'systemd.exec_start.binary_path',
      'systemd.exec_start.file.hash.sha512',
      'systemd.exec_start.name',
      'systemd.exec_start_post.binary_path',
      'systemd.exec_start_post.file.hash.sha512',
      'systemd.exec_start_post.name',
      'systemd.exec_start_pre.binary_path',
      'systemd.exec_start_pre.file.hash.sha512',
      'systemd.exec_start_pre.name',
      'systemd.exec_stop.binary_path',
      'systemd.exec_stop.file.hash.sha512',
      'systemd.exec_stop.name',
      'systemd.exec_stop_post.binary_path',
      'systemd.exec_stop_post.file.hash.sha512',
      'systemd.exec_stop_post.name'
    ]
  }

  if (engine.indexOf('error') > -1) {
    // TODO - add recommendations
    return ['exit_code', 'error_msg']
  }
}

export function getChildPropsFromArrayProp(name: string): string[] | null {
  switch (name) {
    case 'user.ssh.authorized_keys.data':
      return [
        'comment',
        'type',
        'entry',
        'entry_num',
        'hash.md5',
        'hash.sha1',
        'hash.sha256',
        'hash.sha512',
        'key',
        'options',
        'path'
      ]
    default:
      return null
  }
}

export function getEngineResponse(data: TResultDetail['data']) {
  const engine = data.engine.replace('sandfly_engine_', '') as SandflyEngine
  const prop = getResultsPropByEngine(engine)
  return data && data.results[prop]
}

export function getEngineResponseOptions(sandfly: Sandfly) {
  const { response } = sandfly.options
  const engines = Object.keys(response)

  if (engines.length < 1) return []

  return engines
}

export const sandflyFilterPresets = [
  {
    label: 'Active Only',
    filter: {
      items: [
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 1
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Inactive Only',
    filter: {
      items: [
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'false',
          id: 1
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Custom Only',
    filter: {
      items: [
        {
          columnField: 'custom',
          operatorValue: 'is',
          value: 'true',
          id: 1
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type User',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'user',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type Incident',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'incident',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type Process',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'process',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type Policy',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'policy',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type File',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'file',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type Log',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'log',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  },
  {
    label: 'Active and Type Recon',
    filter: {
      items: [
        {
          columnField: 'type',
          operatorValue: 'is',
          value: 'recon',
          id: 1
        },
        {
          columnField: 'active',
          operatorValue: 'is',
          value: 'true',
          id: 2
        }
      ],
      linkOperator: 'and' as GridLinkOperator
    }
  }
]
