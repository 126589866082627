import React, { FC, useEffect } from 'react'
import {
  deleteResult,
  getHost,
  getResult,
  HostResponse,
  TResultResponse,
  whitelistSandflyOnHost
} from '@utils/api'
import { navigate, PageProps } from 'gatsby'
import useApi from '@hooks/useApi'
import GenericPage from '@templates/Generic/Page'
import {
  Code,
  a11yProps,
  TabPanel,
  Tabs,
  Tab,
  Surface,
  Button
} from '@components'

import { ButtonGroup, Grid } from '@mui/material'
import { Block, Delete, Refresh, Share } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { UPGRADE_PAGE } from '@utils/license'
import ResultDetailPageHost from './ResultsDetailPageHost'
import ResultDetailPageSandfly from './ResultDetailPageSandfly'
import ResultDetailPageHunter from './ResultDetailPageHunter'
import { resultLabel } from './resultLabel'

const ResultDetailPage: FC<PageProps> = ({ params, location }) => {
  const { enqueueSnackbar } = useSnackbar()

  const api = useApi<TResultResponse>({
    apiMethod: getResult,
    params: params.id
  })

  const [value, setValue] = React.useState(0)
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const { loading, response, error } = api

  const hostApi = useApi<HostResponse>({
    apiMethod: getHost,
    requestOnMount: false
  })

  const whitelistApi = useApi({
    apiMethod: whitelistSandflyOnHost,
    requestOnMount: false
  })

  const host = hostApi.response ? hostApi.response : false
  // const [host, setHost] = useState<null | HostResponse['data']>(null)

  const rawResult = response && response.data

  const share = async () => {
    try {
      if (response && navigator && navigator.share) {
        await navigator.share({
          title: `${resultLabel(response?.data.status)}: response?.data.name`,
          text: response?.data.results.explanation,
          url: location.href
        })
      }
    } catch (err) {
      console.warn(err)
      enqueueSnackbar('Unable to reach host', {
        variant: 'warning'
      })
    }
  }

  useEffect(() => {
    if (location.pathname.includes('license_required.')) {
      navigate(UPGRADE_PAGE, {
        state: { feature: 'unlimited_alerts' }
      })
    }
  }, [])

  const isWhitelisted =
    response && host
      ? host.sandfly_whitelist.indexOf(response?.data.name) > -1
      : false

  const handleWhitelist = () =>
    whitelistApi
      .makeRequest({
        host: `${host && host?.host_id}`,
        sandfly: `${response?.data.name}`
      })
      .then(() =>
        // TODO: add some error handling in here
        api.makeRequest(params.id)
      )

  const handleDelete = () =>
    deleteResult(params.id).then(() =>
      // TODO: add some error handling in here
      navigate('/results/all/')
    )

  useEffect(() => {
    if (response) {
      hostApi.makeRequest(response.header.host_id).catch((hostError) => {
        console.log(hostError)
      })
    }
  }, [response])

  const handleRecheck = () => {
    navigate('/scan/', {
      state: {
        host_ids: host ? [host.host_id] : [],
        sandfly_list: [response?.data.name]
      }
    })
  }

  const isBrowser = typeof window !== `undefined`

  return (
    <GenericPage
      title={params.id || `Unknown Result`}
      metaTitle={`${params.id} | Result`}
      maxWidth="md"
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          {isBrowser && navigator.share !== undefined && (
            <Button
              disabled={!response}
              onClick={() => share()}
              endIcon={<Share />}
            >
              Share
            </Button>
          )}
          <Button
            disabled={isWhitelisted || !host}
            onClick={() => handleWhitelist()}
            endIcon={<Block />}
          >
            {isWhitelisted ? 'Whitelisted' : 'Whitelist Sandfly'}
          </Button>
          <Button onClick={() => handleDelete()} endIcon={<Delete />}>
            Delete
          </Button>
          <Button
            disabled={loading || !host}
            onClick={() => handleRecheck()}
            endIcon={<Refresh />}
          >
            Recheck
          </Button>
        </ButtonGroup>
      }
      breadcrumbs={[
        {
          title: 'Results',
          to: '/results/'
        },
        {
          title: 'Result Detail'
        }
      ]}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="User Account"
        indicatorColor="primary"
      >
        <Tab label="Result Summary" {...a11yProps(0)} />
        <Tab label="Raw Data" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item sm={8}>
            <ResultDetailPageSandfly api={api} hostApi={hostApi} />
          </Grid>
          <Grid item sm={4}>
            <ResultDetailPageHost api={api} hostApi={hostApi} />
          </Grid>
          <Grid item xs={12}>
            <ResultDetailPageHunter api={api} hostApi={hostApi} />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Surface>
                <Code value={JSON.stringify(error, null, 2)} />
              </Surface>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {response && (
          <Surface>
            <Code height="auto" value={JSON.stringify(rawResult, null, 2)} />
          </Surface>
        )}
      </TabPanel>
    </GenericPage>
  )
}

export default ResultDetailPage
